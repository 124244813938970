<template>
    <div class="fixed inset-0 bg-black overflow-y-auto">
        <div class="container mx-auto text-white py-10">
            <h1 class="text-center font-audiowide text-gold text-3xl">Cometh Top 100 Leaderboard</h1>

            <div class="py-4"></div>

            <div class="max-w-4xl mx-auto">
                <div class="flex items-end font-audiowide">
                    <button
                        class="relative border border-white border-b-0 uppercase bg-black px-4 focus:outline-none"
                        :class="[visibleTab === 'PLAYERS' ? 'top-px py-3' : 'py-2']"
                    >
                        players
                    </button>
                    <!-- <button
                        @click="visibleTab = 'SHIPS'"
                        class="relative border border-white border-b-0 uppercase bg-black px-4 py-2 focus:outline-none"
                        :class="[visibleTab === 'SHIPS' ? 'top-px py-3' : 'py-2']"
                    >
                        ships
                    </button> -->
                    <button class="border border-white border-b-0 uppercase bg-black px-4 py-2 text-gray-600">
                        guilds <span class="text-gold text-opacity-50">(coming soon)</span>
                    </button>
                </div>
                <div class="border border-white">
                    <div class="inline-flex border border-white mx-4 mt-4 font-audiowide text-sm">
                        <button
                            @click="period = 'TODAY'"
                            class="px-4 py-2 focus:outline-none"
                            :class="[period === 'TODAY' ? 'bg-gold text-black' : 'hover:bg-gray-800']"
                        >
                            Today
                        </button>
                        <button
                            @click="period = 'WEEK'"
                            class="px-4 py-2 focus:outline-none"
                            :class="[period === 'WEEK' ? 'bg-gold text-black' : 'hover:bg-gray-800']"
                        >
                            This Week
                        </button>
                        <!-- <button
                            @click="period = 'MONTH'"
                            class="px-4 py-2 focus:outline-none"
                            :class="[period === 'MONTH' ? 'bg-gold text-black' : 'hover:bg-gray-800']"
                        >
                            This Month
                        </button>
                        <button
                            @click="period = 'YEAR'"
                            class="px-4 py-2 focus:outline-none"
                            :class="[period === 'YEAR' ? 'bg-gold text-black' : 'hover:bg-gray-800']"
                        >
                            This Year
                        </button> -->
                        <button
                            @click="period = 'BIG_BANG'"
                            class="px-4 py-2 focus:outline-none"
                            :class="[period === 'BIG_BANG' ? 'bg-gold text-black' : 'hover:bg-gray-800']"
                        >
                            Since Big Bang
                        </button>
                    </div>
                    <div class="py-2"></div>
                    <!-- <div class="inline-flex border border-white mx-4 font-audiowide text-sm">
                        <button
                            @click="sorting = 'EARNINGS'"
                            class="px-4 py-2"
                            :class="[sorting === 'EARNINGS' ? 'bg-gold text-black' : 'hover:bg-gray-800']"
                        >
                            Bigger Earning
                        </button>
                        <button
                            @click="sorting = 'YIELD'"
                            class="px-4 py-2"
                            :class="[sorting === 'YIELD' ? 'bg-gold text-black' : 'hover:bg-gray-800']"
                        >
                            Better Yield
                        </button>
                    </div>
                    <div class="py-2"></div> -->
                    <div v-show="visibleTab === 'PLAYERS'">
                        <div v-if="loadingPlayers" class="flex items-center justify-center py-5">
                            <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path
                                    class="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                            </svg>
                        </div>

                        <table v-else-if="players.length" class="min-w-full">
                            <thead class="border-t border-b border-white bg-gray-300 text-black">
                                <tr>
                                    <th class="text-center">Ranking</th>
                                    <th class="text-left px-4 py-2">Player</th>
                                    <!-- <th class="text-left">Fleet Size</th> -->
                                    <th class="text-right px-4 py-2">Earning</th>
                                    <th class="text-right px-4 py-2">Yield</th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-white divide-opacity-50">
                                <tr v-for="(player, index) in players" :key="`player_${player.id}`">
                                    <td class="font-audiowide text-center text-lg px-4 py-2" :class="{ 'text-4xl text-gold': index < 1 }">
                                        <span v-if="index < 1">🏆</span>
                                        <span v-else>
                                            {{ index + 1 }}
                                        </span>
                                    </td>
                                    <td class="px-4 py-2" :class="{ 'text-lg': index < 1 }">
                                        {{ player.id }}
                                    </td>
                                    <!-- <td class="px-4 py-2" :class="{ 'text-lg': index < 1 }">{{ player.fleet_size }} ships</td> -->
                                    <td class="text-right px-4 py-2" :class="{ 'text-lg': index < 1 }">${{ player.earning.toFixed(2) }}</td>
                                    <td class="text-right px-4 py-2" :class="{ 'text-lg': index < 1 }">{{ player.yield }}%</td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-else class="text-center py-5">NO DATA</div>
                    </div>

                    <div v-show="visibleTab === 'SHIPS'">
                        <div v-if="loadingShips" class="flex items-center justify-center py-5">
                            <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path
                                    class="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                            </svg>
                        </div>
                        <table v-else-if="ships.length" class="min-w-full">
                            <thead class="border-t border-b border-white bg-gray-300 text-black">
                                <tr>
                                    <th class="text-center">Ranking</th>
                                    <th class="text-left px-4 py-2"></th>
                                    <th class="text-left px-4 py-2">Id</th>
                                    <th class="text-right px-4 py-2">Earning</th>
                                    <th class="text-right px-4 py-2">Yield</th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-white divide-opacity-50">
                                <tr v-for="(ship, index) in ships" :key="`ship_${ship.id}`">
                                    <td class="font-audiowide text-center text-xl px-4 py-2" :class="{ 'text-4xl text-gold': index < 1 }">
                                        <span v-if="index < 1">🏆</span>
                                        <span v-else>
                                            {{ index + 1 }}
                                        </span>
                                    </td>
                                    <td class="py-2 text-center flex justify-center">
                                        <img
                                            class="h-20"
                                            :class="{ 'h-28': index < 1 }"
                                            :src="`https://images.service.cometh.io/${ship.id}.png`"
                                            alt=""
                                        />
                                    </td>
                                    <td class="px-4 py-2" :class="{ 'text-xl': index < 1 }">
                                        {{ ship.id }}
                                    </td>
                                    <td class="text-right px-4 py-2" :class="{ 'text-xl': index < 1 }">${{ ship.earning.toFixed(2) }}</td>
                                    <td class="text-right px-4 py-2" :class="{ 'text-xl': index < 1 }">{{ ship.yield }}%</td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-else class="text-center py-5">NO DATA</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="./Leaderboard.ts"></script>
