import { Vue } from 'vue-class-component'
import axios from 'axios'
import Player from '@/models/Player'
import Ship from '@/models/Ship'
import { Watch } from 'vue-property-decorator'

export default class Leaderboard extends Vue {
    visibleTab = 'PLAYERS'
    players: Player[] = []
    ships: Ship[] = []
    period = 'TODAY'
    sorting = 'EARNINGS'

    loadingPlayers = true
    loadingShips = true

    get sortedPlayers() {
        return this.players.sort((a: Player, b: Player) => {
            if (this.sorting === 'EARNINGS') {
                return b.earning - a.earning
            } else if (this.sorting === 'YIELD') {
                return b.yield - a.yield
            }

            return 0
        })
    }

    get sortedShips() {
        return this.ships.sort((a: Ship, b: Ship) => {
            if (this.sorting === 'EARNINGS') {
                return b.earning - a.earning
            } else if (this.sorting === 'YIELD') {
                return b.yield - a.yield
            }

            return 0
        })
    }

    mounted() {
        this.fetchData('TODAY')
    }

    @Watch('period')
    fetchData(period: string) {
        this.fetchPlayers(period)
        // this.fetchShips(period)
    }

    fetchPlayers(period: string) {
        this.loadingPlayers = true
        this.players = []
        axios.get(`${process.env.VUE_APP_API_URL}/graph/players?period=${period}`).then(response => {
            const players: Player[] = []
            response.data.forEach((player: Player) => {
                players.push(Object.assign(new Player(), player))
            })
            this.players = players
            this.loadingPlayers = false
        })
    }

    // fetchShips(period: string) {
    //     this.loadingShips = true
    //     this.ships = []
    //     axios.get(`${process.env.VUE_APP_API_URL}/leaderboard/ships?period=${period}`).then(response => {
    //         const ships: Ship[] = []
    //         response.data.forEach((ship: Ship) => {
    //             ships.push(Object.assign(new Ship(), ship))
    //         })
    //         this.ships = ships
    //         this.loadingShips = false
    //     })
    // }
}
